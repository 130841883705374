import '../../../../css/misterGadget.css'

import { Header } from '../header'
import { YouTubeVideo } from './youtTubeVideos/component'
import urlConfigLandingPage from '../../../../config/urlConfigLandingPage.json'
import urlConfigPages from '../../../../config/urlConfigPages.json'

interface IProps {
  header: boolean
}

export const MisterGadget = ({ header }: IProps) => {
  const { misterGadget } = urlConfigPages

  return (
    <div className='mister-gadget-seccao'>
      {header && <Header origin='misterGadet' imageOffset={150} />}
      <div className='mister-gadget-text-header'>
        {!header ? (
          <a href='/mister_gadget'>
            <h2 className='h2'>Mister Gadget®</h2>
          </a>
        ) : (
          <h2 className='h2'>Mister Gadget®</h2>
        )}
        <h3
          className='h3'
          dangerouslySetInnerHTML={{ __html: misterGadget.texto }}
        ></h3>
      </div>
      <div className='mister-gadget-item'>
        {misterGadget.youTubeVideos.map(video => {
          const iconGrupo =
            urlConfigLandingPage.misterGadget.icons.find(
              icon => icon.name === video.grupo,
            )?.imageUrl || process.env.REACT_APP_NO_IMAGE_THUMB

          return (
            <div className='mister-gadget-item-container'>
              <img style={{ maxWidth: '15rem' }} src={iconGrupo} />
              <YouTubeVideo
                id={video.id}
                grupo={video.grupo}
                titulo={video.titulo}
                videoId={video.videoId}
                size={1}
              />
            </div>
          )
        })}
      </div>
      )
    </div>
  )
}
