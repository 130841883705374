import './styles.css'

import { Category } from '@linkdesign.pt/loptypes'
import { HeaderCategorySelector } from './headerCategorySelector'
import { HeaderInfoStore } from './headerInfo'

interface IProps {
  categorias: Category.ICategoryResponseWithCount[]
}

export const Header = ({ categorias }: IProps) => {
  return (
    <div className='store-header'>
      <HeaderInfoStore />
      <HeaderCategorySelector
        key='HeaderCategorySelector'
        categorias={categorias}
      />
    </div>
  )
}
