import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IStoreType } from '../../types'

export interface configurationState {
  drawerOpen?: boolean
  drawerType?: IStoreType | null
  loginShow?: boolean
  userName?: string
  userType?: string
  userToken?: string
}

const initialStateConfiguration: configurationState = {
  drawerType: 'shoppingCart',
}

export const configurationSlice = createSlice({
  name: 'drawer',
  initialState: initialStateConfiguration,
  reducers: {
    changeDrawer: (state, action: PayloadAction<configurationState>) => {
      state.drawerOpen = action.payload.drawerOpen
      state.drawerType = action.payload.drawerType
    },

    closeLogin: state => {
      state.loginShow = false
      return state
    },
    openLogin: state => {
      state.loginShow = true
      return state
    },
    setUser: (state, action: PayloadAction<configurationState>) => {
      state.userName = action.payload.userName
      state.userType = action.payload.userType
      state.userToken = action.payload.userToken
      return state
    },
  },
})

export const { changeDrawer, closeLogin, openLogin, setUser } =
  configurationSlice.actions

export default configurationSlice.reducer
