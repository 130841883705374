import '../../../../css/lopPoint.css'

import { Body } from './component'
import { Header } from '../header'
import ScrollToTop from '../../../../utils/components/scrollToTop'

export const LopPoint = () => {
  return (
    <>
      <ScrollToTop />
      <Header origin='lopPoint' imageOffset={300} />
      <Body />
    </>
  )
}
