import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { getCategories, getProductList } from './utils/data/getData'
import { useAppDispatch, useAppSelector } from './app/hooks'

import { BackOffice } from './modules/backoffice'
import { Footer } from './modules/footer'
import { LandingPage } from './modules/landing_page'
import LoginModal from './modals/LoginModal'
import { LopCards } from './modules/landing_page/pages/lopCards'
import { LopParceiros } from './modules/landing_page/pages/lopParceiros'
import { LopPoint } from './modules/landing_page/pages/lopPoint'
import { LopRetail } from './modules/landing_page/pages/lopRetail'
import { LopStore } from './modules/lop_store'
import { MacBookConfigurator } from './modules/McBookConfigurator'
import { MisterGadget } from './modules/landing_page/pages/mister_gadget'
import { NavBar } from './modules/navBar'
import { PageNotFound } from './modules/errors'
import { Projectos } from './modules/landing_page/pages/projectos'
import { StoreCategory } from './modules/lop_store/storeCategory'
import { StoreProduct } from './modules/lop_store/storeProduct'
import { StoreSelectedCategory } from './modules/lop_store/storeSelectedCategory'
import { ToastContainer } from 'react-toastify'
import { loading as loadingCategories } from './app/features/category/category-slicer'
import { loading as loadingProducts } from './app/features/product/product-slicer'
import useDocumentTitle from './useDocumentTitle'
import { useEffect } from 'react'

function App() {
  const dispatch = useAppDispatch()
  function LandingPageWithTitle() {
    useDocumentTitle('LOP - Surprise the world')
    return <LandingPage />
  }

  function MisterGadgetWithTitle() {
    useDocumentTitle('LOP - Mister Gadget®')
    return <MisterGadget header />
  }

  function LopStoreWithTitle() {
    useDocumentTitle('LOP - Loja Online')
    return <LopStore key={'store'} />
  }

  function LopStoreProductWithTitle() {
    useDocumentTitle('LOP - Loja Online Produto')
    return <StoreProduct />
  }
  function LopStoreCategoryWithTitle() {
    useDocumentTitle('LOP - Loja Online Produto Categoria')
    return <StoreCategory />
  }
  function LopStoreSelectedCategoryWithTitle() {
    useDocumentTitle('LOP - Loja Online Produto Categoria')
    return <StoreSelectedCategory />
  }
  function LopBackOffice() {
    const { userType } = useAppSelector(state => state.configuration)
    if (!userType || userType !== 'ADMINISTRATOR') {
      window.location.replace('/')
    } else {
      useDocumentTitle('LOP - BackOffice')
      return <BackOffice />
    }
  }
  function MacBookConfiguratorWithTitle() {
    useDocumentTitle('LOP - Configure o se MacBookPro')
    return <MacBookConfigurator />
  }

  const { estado: estadoCategorias } = useAppSelector(state => state.categories)

  const { estado: estadoProdutos } = useAppSelector(state => state.products)
  const { loginShow } = useAppSelector(state => state.configuration)

  useEffect(() => {
    const getData = async () => {
      const data = await getCategories()
      dispatch(loadingCategories({ dados: data, estado: 'success' }))
    }
    getData()
  }, [estadoCategorias])

  useEffect(() => {
    const getData = async () => {
      const data = await getProductList()
      dispatch(loadingProducts({ dados: data, estado: 'success' }))
    }
    getData()
  }, [estadoProdutos])

  return (
    <>
      <div className='body100vh'>
        <div className='pace-overlay'></div>
        <ToastContainer autoClose={2000} />
        <Router>
          <NavBar />
          <LoginModal show={loginShow ? loginShow : false} />
          <Routes>
            <Route path='/' element={<LandingPageWithTitle />} />
            <Route
              path='/lop_store'
              element={<LopStoreWithTitle key={'store'} />}
            />
            <Route
              path='/lop_store/product/:id'
              element={<LopStoreProductWithTitle />}
            />
            <Route
              path='/lop_store/category/:id'
              element={<LopStoreCategoryWithTitle />}
            />{' '}
            <Route
              path='/lop_store/selected_category/:id'
              element={<LopStoreSelectedCategoryWithTitle />}
            />
            <Route path='/lop_retail' element={<LopRetail />} />
            <Route path='/lop_cards' element={<LopCards />} />
            <Route path='/lop_point' element={<LopPoint />} />
            <Route path='/lop_parceiros' element={<LopParceiros />} />
            <Route path='/mister_gadget' element={<MisterGadgetWithTitle />} />
            <Route path='/projectos' element={<Projectos />} />
            <Route path='/backoffice' element={<LopBackOffice />} />
            <Route
              path='/lop_store/mac_book_configurator'
              element={<MacBookConfiguratorWithTitle />}
            />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Router>
        <ToastContainer />
      </div>
      <Footer />
    </>
  )
}

export default App
