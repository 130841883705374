import './styles.css'

import * as React from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

export const NavBottom = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleClick = (path: string) => {
    navigate(path, { relative: 'route' })
  }
  const lopMenu = [
    { id: 1, name: 'HOME', path: '/' },
    { id: 2, name: 'LOP', path: '/#quem_somos' },
    { id: 3, name: 'PARCEIROS', path: '/lop_parceiros' },
    { id: 4, name: 'PROJETOS', path: '/projectos' },
    { id: 5, name: 'MISTER GADGET®', path: '/mister_gadget' },
    { id: 6, name: 'CONTACTOS', path: '/#contactos' },
    { id: 6, name: 'LOP STORE', path: '/lop_store' },
    {
      id: 7,
      name: 'MAC BOOK CONFIGURATOR',
      path: '/lop_store/mac_book_configurator',
    },
  ]

  return (
    <AppBar
      position='static'
      elevation={0}
      sx={{ backgroundColor: 'transparent' }}
    >
      <div className='lop-nav-bottom-header'>
        <Container maxWidth='xl'>
          <Toolbar
            disableGutters
            variant='dense'
            style={{
              minHeight: 24,
              top: '-25px',
              left: '2rem',
            }}
          >
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='#app-bar-with-responsive-menu'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            ></Typography>
            {/* // *** mobile *** */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {lopMenu.map(menu => (
                  <MenuItem
                    style={{ cursor: 'pointer' }}
                    key={menu.id}
                    onClick={() => handleClick(menu.path)}
                  >
                    <Typography textAlign='center'>{menu.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* // *** pc *** */}
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', md: 'flex' },
                paddingLeft: '3rem',
              }}
            >
              {lopMenu.map(menu => {
                return (
                  <>
                    <button
                      className='lop-nav-navbar-button'
                      onClick={() => handleClick(menu.path)}
                    >
                      {menu.name}
                    </button>{' '}
                  </>
                )
              })}
            </Box>
          </Toolbar>
        </Container>
      </div>
    </AppBar>
  )
}
