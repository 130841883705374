import './styles.css'

import {
  closeLogin,
  setUser,
} from '../app/features/configuration/configuration-slicer'

import { login } from '../utils/data/sendData'
import { useAppDispatch } from '../app/hooks'
import { useState } from 'react'

interface IProps {
  show: boolean
}

const LoginModal = ({ show = false }: IProps) => {
  const dispatch = useAppDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const result = await login({ email, password })
    if (result) {
      dispatch(
        setUser({
          userName: result.userName,
          userToken: result.userToken,
          userType: result.type,
        }),
      )
    }
    closeModal()
  }

  const closeModal = () => {
    dispatch(closeLogin())
  }

  if (!show) return null

  return (
    <div className='modal-overlay'>
      <div className='login-modal'>
        <div className='login-modal-header'>
          <button className='close-btn' onClick={closeModal}>
            X
          </button>
          <span className='login-text'>Login</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>email: </label>
            <input
              className='form-text '
              type='text'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Password: </label>
            <input
              className='form-text '
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>

          <div className='btn-container'>
            <a className='forget-password'> forget Password?</a>
            <button className='btn btn-login' type='submit'>
              Login
            </button>
            <button className='btn btn-signin' type='submit'>
              SignIn
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginModal
