import { Minus, Plus, Trash } from '@phosphor-icons/react'
import {
  addToCart,
  decreaseFromCart,
  removeFromCart,
} from '../../../app/features/shoppingCart/shoppingCart-slicer'
import {
  addToList,
  decreaseFromList,
  removeFromList,
} from '../../../app/features/wishList/wishList-slicer'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'

import { Button } from '@mui/material'
import { IStoreType } from '../../../app/types'

interface IProps {
  cartType: IStoreType
  product: {
    id: string
    quantity: number
  }
}

export const StoreItem = ({ product, cartType }: IProps) => {
  const { dados: produtos } = useAppSelector(state => state.products)
  if (!produtos) return <p>"erro"</p>
  const produto = produtos.find(produto => {
    try {
      return produto.id === product.id
    } catch (error) {
      console.error(error)
    }
  })

  const dispatch = useAppDispatch()

  const handleProductIncrease = () => {
    if (!product || !product.id) return
    cartType === 'shoppingCart'
      ? dispatch(addToCart({ id: product.id, quantity: 1 }))
      : dispatch(addToList({ id: product.id, quantity: 1 }))
  }

  const handleProductDecrease = () => {
    if (!product || !product.id) return
    cartType === 'shoppingCart'
      ? dispatch(decreaseFromCart({ id: product.id, quantity: 1 }))
      : dispatch(decreaseFromList({ id: product.id, quantity: 1 }))
  }
  const handleProductRemove = () => {
    if (!product || !product.id) return
    cartType === 'shoppingCart'
      ? dispatch(removeFromCart({ id: product.id, quantity: 0 }))
      : dispatch(removeFromList({ id: product.id, quantity: 0 }))
  }

  const image = produto?.LopFiles?.find(a => a.file_type === 'PRINCIPAL')
    ? produto?.LopFiles?.find(a => a.file_type === 'PRINCIPAL')?.url
    : process.env.REACT_APP_NO_IMAGE_THUMB

  const total = produto
    ? `${product.quantity} x ${produto?.ProductPrices[0].price_sale} € = ${(
        Math.round(
          produto?.ProductPrices[0].price_sale * product?.quantity * 100,
        ) / 100
      ).toFixed(2)} €`
    : ''

  return !produto ? (
    <p>Artigo indisponivel</p>
  ) : (
    <div className='store-panel-product'>
      <p className='store-panel-product-text'>{produto.designation}</p>
      <div className='store-panel-product-image-container'>
        {<img className='store-panel-product-image' src={image} />}
      </div>
      <p className='store-panel-product-text-price'>{total}</p>
      <Button onClick={handleProductIncrease}>
        <Plus size={24} />
      </Button>
      <Button onClick={handleProductDecrease}>
        <Minus size={24} />
      </Button>
      <Button onClick={handleProductRemove}>
        <Trash size={24} />
      </Button>
    </div>
  )
}
