import '../../../../css/lopServicos.css'

import urlConfigPages from '../../../../config/urlConfigPages.json'

export const LopServicos = () => {
  return (
    <div className='servicos-seccao'>
      <div className='servicos-text-header'>
        <h2 className='h2'>servicos </h2>
      </div>
      <div className='servicos-text'>
        <a
          dangerouslySetInnerHTML={{
            __html: urlConfigPages.lopServicos.texto,
          }}
        ></a>
      </div>
    </div>
  )
}
