import { Checkbox, FormControl, FormControlLabel, Input } from '@mui/material'
import { swallInfo, swallType } from '../../../../../utils/swall'

import { createClientMessageFromWebsite } from '../../../../../utils/data/sendData'
import { useState } from 'react'

export const ContactForm = () => {
  const [nome, setNome] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  const [telefone, setTelefone] = useState<string>('')
  const [mensagem, setMensagem] = useState<string>('')
  const [checked, setChecked] = useState<boolean>(false)

  async function handleSubmitMail() {
    try {
      const result = await createClientMessageFromWebsite({
        name: nome,
        mail,
        telephone: telefone,
        message: mensagem,
      })
      if (!result) {
        swallInfo('Erro', 'Erro no envio da mensagem!', swallType.error)
        return
      }
      swallInfo('Ok', result, swallType.success)
    } catch (error) {
      swallInfo('Erro', 'Erro no envio da mensagem!', swallType.error)
      return
    }
  }

  return (
    <div className='contactos-form'>
      <FormControl fullWidth>
        <div className='contactos-text-box-area'>
          <Input
            id='nome'
            className='contactos-text-box-area-label'
            placeholder='Nome'
            onChange={e => setNome(e.target.value)}
          />
        </div>
        <div className='contactos-text-box-area'>
          <Input
            id='mail'
            className='contactos-text-box-area-label'
            placeholder='mail'
            onChange={e => setMail(e.target.value)}
          />
        </div>
        <div className='contactos-text-box-area'>
          <Input
            id='Telefone'
            className='contactos-text-box-area-label'
            placeholder='Telefone'
            onChange={e => setTelefone(e.target.value)}
          />
        </div>
        <div className='contactos-text-box-area'>
          <Input
            id='mensagem'
            className='contactos-text-box-area-text-area'
            placeholder='Mensagem'
            onChange={e => setMensagem(e.target.value)}
          />
        </div>

        <div className='contactos-check-box'>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={false}
                onChange={e => setChecked(e.target.checked)}
              />
            }
            label={
              <div>
                <span>
                  Declaro que li, aceito e autorizo que usem os meus dados para
                  entrar em contacto comigo de acordo com a Política de
                  Privacidade{' '}
                </span>
                <a className='contactos-link' href={'/terms'}>
                  Saber mais
                </a>
              </div>
            }
          />
        </div>

        <div className='contactos-button-box'>
          <button
            className='contactos-send-button'
            title='Enviar'
            disabled={!checked}
            onClick={() => handleSubmitMail()}
          >
            Enviar
          </button>
        </div>
      </FormControl>
    </div>
  )
}
