import './styles.css'

import { ICardsSize } from '../../../../config/types'
import { Product } from '@linkdesign.pt/loptypes'
import { StarRating } from '../../../../utils/components/startRating'
import { addToCart } from '../../../../app/features/shoppingCart/shoppingCart-slicer'
import { addToList } from '../../../../app/features/wishList/wishList-slicer'
import { useAppDispatch } from '../../../../app/hooks'

interface IProps {
  size: ICardsSize
  product: Product.IFindProductResponse
}

export const ProductListItem = ({ product, size }: IProps) => {
  const { id, designation, LopFiles, rating, ProductPrices } = product
  const image = LopFiles?.find(a => a.file_type === 'PRINCIPAL')?.url
    ? LopFiles?.find(a => a.file_type === 'PRINCIPAL')?.url
    : process.env.REACT_APP_NO_IMAGE_THUMB

  const dispatch = useAppDispatch()

  const handleShoppingCart = () => {
    if (!product || !product.id) return
    dispatch(addToCart({ id: product.id, quantity: 1 }))
  }
  const handleWishList = () => {
    if (!product || !product.id) return
    dispatch(addToList({ id: product.id, quantity: 1 }))
  }

  return (
    <div className={`store-product${size}`}>
      <a href={`/lop_store/product/${id}`}>
        <p className={`store-product-text-info${size}`}>NOVO</p>
        <p className={`store-product-text${size}`}>{designation}</p>
        <p
          className={`store-product-text-price${size}`}
        >{`Desde: ${ProductPrices[0].price_sale} €`}</p>
        <div className={`store-product-image-container${size}`}>
          {<img className={`store-product-image${size}`} src={image} />}
        </div>
        <StarRating key={id} stars={rating || 4} />
      </a>
      <div className={`icons-container${size}`}>
        <i
          className={`fa fa-shopping-bag fa-shopping-bag${size}`}
          onClick={() => handleShoppingCart()}
        ></i>
        <i
          className={`fa  fa-heart fa-shopping-bag${size}`}
          onClick={() => handleWishList()}
        ></i>
      </div>
    </div>
  )
}
