import '../src/css/main.css'
import '../src/css/font-awesome.css'

import { persistor, store } from './app/store'

import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { TemporaryDrawer } from './modules/lop_store/storePanel'

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(rootElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <TemporaryDrawer />
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
