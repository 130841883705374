import { ThreeCircles } from 'react-loader-spinner'

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <ThreeCircles
        visible={true}
        height='100'
        width='100'
        color='#4ac4eb'
        ariaLabel='three-circles-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </div>
  )
}

export default Loading
