import urlConfigLandingPage from '../../../config/urlConfigLandingPage.json'

interface Iprops {
  sectionNumber: number
}

interface ILpSelected {
  id: number
  text: string
  subText: string
  imageUrl: string
  logoUrl: string
  url: string
}

export const LandingPageSection = ({ sectionNumber }: Iprops) => {
  const lpSelected: ILpSelected | undefined =
    urlConfigLandingPage.langingPage.find(lp => lp.id === sectionNumber)
  if (!lpSelected) return <p>Erro</p>

  return (
    <>
      <div className=' landing-page-image-container'>
        <img
          src={lpSelected.imageUrl}
          alt={lpSelected.imageUrl}
          className='landing-page-image'
        />
        <a href={lpSelected.url} rel='noreferrer'>
          <div className='landing-page-text'>
            <img src={lpSelected.logoUrl} alt={lpSelected.logoUrl} />
            <h4>{lpSelected.subText}</h4>

            <h6>Saiba Mais</h6>
          </div>
        </a>
      </div>
    </>
  )
}
