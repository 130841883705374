import '../../../../css/lopParceiros.css'

import AutoSlider from './slideShow'

export const LopParceiros = () => {
  return (
    <div id='lop-parceiros' className='parceiros-seccao'>
      <div className='parceiros-text-header'>
        <h2 className='h2'>parceiros </h2>
      </div>
      <div className='slide-show-slider-container'>
        <AutoSlider />
      </div>
      <div className='parceiros-button'>
        <a href='/lop_parceiros' title='parceiros'>
          Parceiros
        </a>
      </div>
    </div>
  )
}
