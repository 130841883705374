import './styles.css'

import { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { NavBottom } from './navBottom'
import { NavBottomStore } from './navBottomStore'
import { NavTop } from './navTop'

const currentUrl = window.location.pathname
const lopStore = !!currentUrl.includes('lop_store')
console.log('window.location.pathname =>', window.location.pathname)
export const NavBar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY

      if (currentScrollPos < 100) {
        setVisible(true)
      } else if (prevScrollPos < currentScrollPos) {
        setVisible(false)
      } else {
        setVisible(true)
      }

      setPrevScrollPos(currentScrollPos)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos])

  if (lopStore === true) {
    return (
      <Box
        className={`lop-nav-bar lop-nav-navbar lop-nav-bar-store ${
          visible ? '' : 'smooth-hidden'
        }`}
      >
        <NavTop lopStore={true} />
        <NavBottomStore />
      </Box>
    )
  } else {
    return (
      <Box
        className={`lop-nav-bar lop-nav-navbar ${
          visible ? '' : 'smooth-hidden'
        }`}
      >
        <NavTop lopStore={false} />
        <NavBottom />
      </Box>
    )
  }
}
