import Swal from 'sweetalert2'

export enum swallType {
  success = 'success',
  info = 'info',
  question = 'question',
  error = 'error',
}

export const swallInfo = (title: string, data: string, type: swallType) => {
  Swal.fire(title.toUpperCase(), data, type)
}
