import { Contactos } from './sections/contactos'
import { Header } from './header'
import { LopParceiros } from './sections/lop_parceiros'
import { LopServicos } from './sections/lop_servicos'
import { Projectos } from './sections/projectos'
import { QuemSomos } from './sections/quem_somos'
import ScrollToTop from '../../utils/components/scrollToTop'
export const LandingPage = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <QuemSomos />
      <LopServicos />
      <LopParceiros />
      <Projectos />
      <Contactos />
    </>
  )
}
