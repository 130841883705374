import './styles.css'

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Props: any
}

export const List = ({ Props }: IProps) => {
  return (
    <div className='list-app'>
      <div className='list-container'>{Props}</div>
    </div>
  )
}
