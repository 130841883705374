import 'react-toastify/dist/ReactToastify.css'

import { toast } from 'react-toastify'

export type IToastType = 'success' | 'error' | 'info' | 'warning'
interface IProps {
  text: string
  type: IToastType
  toastId: string
}

export const toastNotify = ({ text, type, toastId }: IProps) => {
  if (type === 'success')
    toast.success(text, {
      position: 'top-right',
      autoClose: 2000,
      toastId: toastId,
    })
  if (type === 'info')
    toast.info(text, {
      position: 'top-right',
      autoClose: 2000,
      toastId: toastId,
    })
  if (type === 'error')
    toast.error(text, {
      position: 'top-right',
      autoClose: 2000,
      toastId: toastId,
    })
  if (type === 'warning')
    toast.warning(text, {
      position: 'top-right',
      autoClose: 2000,
      toastId: toastId,
    })
}
