import { Header } from '../header'
import { MisterGadget } from '../mister_gadget'
import { ProjectBody } from './project_body'
import ScrollToTop from '../../../../utils/components/scrollToTop'

export const Projectos = () => {
  return (
    <>
      <ScrollToTop />
      <Header origin='projetos' imageOffset={60} />
      <ProjectBody />
      <MisterGadget header={false} />
    </>
  )
}
