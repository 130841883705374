import '../../../../../css/projectos.css'
interface IProps {
  image: string
  text: string
  name: string
  link_url: string
}

export const LopItem = ({ image, text, name, link_url }: IProps) => {
  return (
    <div className='projectos-lop-logo'>
      <a href={link_url}>
        <img decoding='async' loading='lazy' src={image} alt={name} />
      </a>
      <br />
      <h5>{text}</h5>
    </div>
  )
}
