import './styles.css'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useEffect, useRef, useState } from 'react'

import BlockUi from '@availity/block-ui'
import Loading from '../../../../utils/components/loading'
import { ProductListItem } from '../../body/productListItem'
import { Slider } from '../../body/sections/slider'
import { getProductList } from '../../../../utils/data/getData'
import { loading } from '../../../../app/features/product/product-slicer'

interface IProps {
  productCategoryId?: string
  productId?: string
}

export const SimilarProduct = ({ productCategoryId, productId }: IProps) => {
  const dispatch = useAppDispatch()
  const sliderRef = useRef<HTMLInputElement>(null)
  const [width, setwidth] = useState(0)

  const { estado, dados: produtos } = useAppSelector(state => state.products)

  useEffect(() => {
    const getData = async () => {
      if (estado === 'idle') {
        const productsData = await getProductList()
        dispatch(loading({ dados: productsData, estado: 'success' }))
      }
    }
    getData()
  }, [])
  if (estado === 'loading' || estado === 'idle') {
    return <Loading />
  } else if (estado === 'failed' || !produtos) {
    return <p>Erro{'erro'}</p>
  } else {
    const produtosDaCategoria = produtos.filter(
      produto => produto.Category.id === productCategoryId,
    )
    if (!produtosDaCategoria) throw new Error('Produtos não encontrados!')

    useEffect(() => {
      if (!sliderRef.current) return
      setwidth(sliderRef.current.clientWidth)
    })
    return (
      <BlockUi tag='div' blocking={estado !== 'success'}>
        <div className='similar-container'>
          <div className='similar-inner-container'>
            <hr className='similar-line' />
            <span className='similar-title'>Produtos da mesma categoria</span>
            <Slider
              parentSize={width}
              props={
                <div className='store-section-product' ref={sliderRef}>
                  {produtosDaCategoria
                    .filter(p => p.id !== productId)
                    .map(product => {
                      return (
                        <ProductListItem
                          key={product.id}
                          product={product}
                          size='small'
                        />
                      )
                    })}
                </div>
              }
            />
          </div>
        </div>
      </BlockUi>
    )
  }
}
