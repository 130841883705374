import './styles.css'

import BlockUi from '@availity/block-ui'
import { ICategoryResponseWithCount } from '@linkdesign.pt/loptypes/dist/modules/Product/Category/interface'
import Loading from '../../../utils/components/loading'
import { StoreSection } from '../body/sections'
import { useAppSelector } from '../../../app/hooks'
import { useParams } from 'react-router-dom'

export const StoreCategory = () => {
  const { id } = useParams()

  const { estado, dados: categorias } = useAppSelector(
    state => state.categories,
  )

  if (estado === 'loading' || estado === 'idle') {
    return <Loading />
  } else if (estado === 'failed' || !categorias) {
    return <p>Erro{'erro'}</p>
  } else {
    const catsIDs: string[] = []
    categorias.map(category => {
      if (category.parent === id) {
        catsIDs.push(category.id)
      }
    })

    const selectedCategories: ICategoryResponseWithCount[] = []

    catsIDs.forEach(a => {
      categorias
        .filter(ct => ct.level === 3)
        .forEach(cat => {
          if (cat.parent === a) {
            selectedCategories.push(cat)
          }
        })
    })

    return (
      <BlockUi tag='div' blocking={estado !== 'success'}>
        <div className='store-category'>
          {selectedCategories.map(cat => {
            if (cat.id && cat.products_count) {
              return (
                <StoreSection
                  key={cat.id}
                  sectionName={cat.name}
                  sectionDescription={''}
                  categoryId={cat.id}
                  subCategory={false}
                />
              )
            }
          })}
        </div>
      </BlockUi>
    )
  }
}
