import '../../../../css/lopRetail.css'

import { Body } from './component'
import { Header } from '../header'
import ScrollToTop from '../../../../utils/components/scrollToTop'

export const LopRetail = () => {
  return (
    <>
      <ScrollToTop />
      <Header origin='lopRetail' imageOffset={300} />
      <Body />
    </>
  )
}
