import './styles.css'

import { Category } from '@linkdesign.pt/loptypes'
import { IconSelector } from './iconSelector'

interface IProps {
  categorias: Category.ICategoryResponseWithCount[]
}

export const HeaderCategorySelector = ({ categorias }: IProps) => {
  return (
    <div className='store-category-selector-header-container'>
      {categorias
        .filter(a => a.level === 1)
        .map(cat => {
          return <IconSelector key={cat.id} category={cat} />
        })}
    </div>
  )
}
