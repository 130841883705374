import './styles.css'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme

import { Box, Tab, Tabs, Typography } from '@mui/material'

import ImportProducts from './importProducts'
import { PageUnderConstruction } from '../errors'
import { ProductsTable } from './tables/products'
import { useAppSelector } from '../../app/hooks'
import { useState } from 'react'

export const BackOffice = () => {
  const { userName } = useAppSelector(state => state.configuration)

  const [value, setValue] = useState(0)

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className='back-office-container'>
      <div className='back-office-user-info'>
        <h6>{`Bem vindo(a) ${userName}`}</h6>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Importação de Dados' {...a11yProps(0)} />
          <Tab label='Tabela Produtos' {...a11yProps(1)} />
          <Tab label='Tabela Utilizadores' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ImportProducts />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ProductsTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <PageUnderConstruction />
      </CustomTabPanel>
    </div>
  )
}
