import { ContactForm } from './Items/form'
import { ContactInfo } from './Items/info'

export const Component = () => {
  return (
    <section className='contactos-seccao'>
      <ContactInfo />
      <ContactForm />
    </section>
  )
}
