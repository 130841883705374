import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  addToWishList,
  decreaseFromWishList,
  removeFromWishList,
} from './actions'

export interface IWishList {
  id: string
  quantity: number
}
export interface IWishListState {
  whishListItems: IWishList[] | null
}

// Define the initial state using that type
const initialState: IWishListState = {
  whishListItems: null,
}

export const wishListSlice = createSlice({
  name: 'wishList',
  initialState,
  reducers: {
    clearList: state => {
      state.whishListItems = []
    },
    addToList: (state, action: PayloadAction<IWishList>) => {
      return addToWishList(state, action)
    },
    decreaseFromList: (state, action: PayloadAction<IWishList>) => {
      return decreaseFromWishList(state, action)
    },
    removeFromList: (state, action: PayloadAction<IWishList>) => {
      return removeFromWishList(state, action)
    },
  },
})

export const { clearList, addToList, removeFromList, decreaseFromList } =
  wishListSlice.actions

export default wishListSlice.reducer
