import './styles.css'

import BlockUi from '@availity/block-ui'
import Loading from '../../../utils/components/loading'
import { StoreSection } from '../body/sections'
import { useAppSelector } from '../../../app/hooks'
import { useParams } from 'react-router-dom'

export const StoreSelectedCategory = () => {
  const { id } = useParams()

  const { estado, dados: categorias } = useAppSelector(
    state => state.categories,
  )

  if (estado === 'loading' || estado === 'idle') {
    return <Loading />
  } else if (estado === 'failed' || !categorias) {
    return <p>Erro{'erro'}</p>
  } else {
    const selectedCategory = categorias.find(category => category.id === id)

    if (!selectedCategory) return <p>Erro</p>
    return (
      <BlockUi tag='div' blocking={estado !== 'success'}>
        <div className='store-category-row'>
          <StoreSection
            key={selectedCategory.id}
            sectionName={selectedCategory.name}
            sectionDescription={''}
            categoryId={selectedCategory.id}
            subCategory={true}
          />
        </div>
      </BlockUi>
    )
  }
}
