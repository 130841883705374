import './styles.css'

import { AttributeSelector } from '../../../../utils/components/AttributeSelector'
import { Product } from '@linkdesign.pt/loptypes'
import { Tooltip } from '@mui/material'
import { addToCart } from '../../../../app/features/shoppingCart/shoppingCart-slicer'
import { addToList } from '../../../../app/features/wishList/wishList-slicer'
import { useAppDispatch } from '../../../../app/hooks'

interface IProps {
  product: Product.IFindProductResponse
}

export const BodyProduct = ({ product }: IProps) => {
  const image = product.LopFiles?.find(a => a.file_type === 'PRINCIPAL')?.url
    ? product.LopFiles?.find(a => a.file_type === 'PRINCIPAL')?.url
    : process.env.REACT_APP_NO_IMAGE_THUMB

  const shoppingCartDispatch = useAppDispatch()
  const whishListDispatch = useAppDispatch()

  const handleShoppingCart = () => {
    if (!product || !product.id) return
    shoppingCartDispatch(
      addToCart({
        id: product.id,
        quantity: 1,
      }),
    )
  }
  const handleWishList = () => {
    if (!product || !product.id) return
    whishListDispatch(
      addToList({
        id: product.id,
        quantity: 1,
      }),
    )
  }

  return (
    <>
      <hr className='info-line' />
      <div className='body-inner-container'>
        <div className='body-inner-left-side'>
          <Tooltip title={product.designation}>
            <span className='body-inner-left-side-designation'>
              {product.designation}
            </span>
          </Tooltip>
          <span className='body-inner-left-side-price'>
            {product?.ProductPrices[0]?.price_sale} €
          </span>
          <div className='body-inner-left-colors'>
            <AttributeSelector
              key={product.id}
              productsAttributes={product.ProductAttributes}
              designation={product.designation}
            />
          </div>
          <div className='body-inner-left-button' onClick={handleShoppingCart}>
            <span>Comprar</span>
          </div>
          <div className='body-inner-left-info'>
            <span className='normal-text'>
              Precisa de algum tempo? Mantenha todas as seleções guardando este
              dispositivo em Guardados e regresse a qualquer momento para
              retomar no ponto em que ficou.
              <br />
              <br />
              <span onClick={handleWishList}>
                <i className='fa fa-heart'>
                  <span className='body-text'> Lista de desejos </span>
                </i>
              </span>
            </span>
          </div>
        </div>
        <div className='body-inner-right-side'>
          <img className='body-image' src={image} />
        </div>
      </div>
    </>
  )
}
