import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'

import { BsToggle2Off, BsToggle2On } from 'react-icons/bs'
import { useEffect, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { Product } from '@linkdesign.pt/loptypes'
import { getProductList } from '../../../utils/data/getData'

type IFields =
  | 'id'
  | 'designacao'
  | 'estado'
  | 'inventario'
  | 'preco_venda'
  | 'categoria'
  | 'imagem'
  | 'fornecedor'
interface IColumnDef {
  headerName: string
  field?: IFields

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellRenderer?: (params?: any) => JSX.Element
  sortable?: boolean
  filter?: boolean
  hide?: boolean
  flex?: number
}

export const ProductsTable = () => {
  const [productsList, setProductsList] = useState<
    Product.IFindProductResponse[]
  >([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductList()
        setProductsList(result)
      } catch (error) {
        console.error('Error fetching product list:', error)
      }
    }
    fetchData()
  }, [])

  const listOfProducts = productsList?.map(product => ({
    id: product.id,
    designacao: product.designation,
    preco_venda: Number(product.ProductPrices[0].price_sale.toFixed(2)),
    categoria: product.Category.name,
    imagem: product.LopFiles.find(lf => lf.index === 1)?.url,
    estado: product.online,
    fornecedor: product.Provider.company_name,
    inventario: '0',
  }))
  console.log(listOfProducts)
  if (!listOfProducts) return null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageCell = (props: any) => {
    console.log(props.value)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: 'auto',
        }}
      >
        <img src={props.value} />
      </div>
    )
  }

  const butttonsCell = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 60,
          alignContent: 'center',
          justifyContent: 'center',
          height: '100%',
          maxWidth: '100%',
        }}
      >
        <button>Editar</button>
        <button>Apagar</button>
      </div>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const estadoCell = (props: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 60,
          alignContent: 'center',
          justifyContent: 'center',
          height: '100%',
          maxWidth: '100%',
        }}
      >
        {props.value ? (
          <BsToggle2Off size={32} color='green' />
        ) : (
          <BsToggle2On size={32} color='red' />
        )}
      </div>
    )
  }

  const columnDefs: IColumnDef[] = [
    { headerName: 'ID', field: 'id', hide: true },
    {
      headerName: 'image',
      field: 'imagem',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (props: any) => imageCell(props),
    },
    {
      headerName: 'Designação',
      field: 'designacao',
      sortable: true,
      filter: true,
      flex: 2,
    },
    {
      headerName: 'Estado',
      field: 'estado',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (props: any) => estadoCell(props),
      sortable: true,
    },
    { headerName: 'Preço', field: 'preco_venda', sortable: true },
    {
      headerName: 'Categoria',
      field: 'categoria',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Fornecedor',
      field: 'fornecedor',
      sortable: true,
      filter: true,
    },
    { headerName: 'Inventário', field: 'inventario', sortable: true },
    {
      headerName: 'Opções',
      cellRenderer: () => butttonsCell(),
    },
  ]

  return (
    <div className='ag-theme-material' style={{ height: 700, color: 'white' }}>
      <AgGridReact
        onCellClicked={() => console.log('teste')}
        rowData={listOfProducts}
        columnDefs={columnDefs}
        rowHeight={100}
        gridOptions={{ rowSelection: 'multiple' }}
        pagination
        animateRows
      />
    </div>
  )
}
