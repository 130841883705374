import './styles.css'

import * as React from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'

import AppBar from '@mui/material/AppBar'
import BlockUi from '@availity/block-ui'
import Box from '@mui/material/Box'
import { Category } from '@linkdesign.pt/loptypes'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MenuListBottom } from './menuListBottom'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { getCategories } from '../../utils/data/getData'
import { loading } from '../../app/features/category/category-slicer'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export interface ICategoryResponseWithCountAndHref
  extends Category.ICategoryResponseWithCount {
  href?: string
}

export const NavBottomStore = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { estado, dados: categorias } = useAppSelector(
    state => state.categories,
  )

  useEffect(() => {
    const getData = async () => {
      if (estado === 'idle') {
        const categoriesData = await getCategories()
        dispatch(loading({ dados: categoriesData, estado: 'success' }))
      }
    }
    getData()
  }, [])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCloseNavMenu = (a: any) => {
    if (a.id) {
      navigate(`/lop_store/category/${a.id}`)
      setAnchorElNav(null)
    }
  }
  if (!categorias) return <p>erro ao carregar Categorias</p>
  const categoriasMenu: ICategoryResponseWithCountAndHref[] = [
    ...categorias,
    {
      products_count: 0,
      childrens_count: 0,
      id: (categorias.length + 1).toString(),
      name: 'MAC BOOK CONFIGURATOR',
      level: 1,
      parent: '',
      href: '/lop_store/mac_book_configurator',
    },
  ]
  return (
    <BlockUi blocking={estado !== 'success'}>
      <AppBar
        position='static'
        elevation={0}
        sx={{ backgroundColor: 'transparent' }}
      >
        <div className='lop-nav-bottom-header'>
          <Container maxWidth='xl'>
            <Toolbar disableGutters variant='dense' style={{ minHeight: 24 }}>
              <Typography
                variant='h6'
                noWrap
                component='a'
                href='#app-bar-with-responsive-menu'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              ></Typography>
              {/* // *** mobile *** */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleOpenNavMenu}
                  color='inherit'
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {categoriasMenu
                    .filter(c => c.level === 1)
                    .map(cat => (
                      <MenuItem
                        style={{ cursor: 'pointer' }}
                        key={cat.id}
                        onClick={() => handleCloseNavMenu(cat)}
                      >
                        <Typography textAlign='center'>{cat.name}</Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
              {/* // *** pc *** */}
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: 'none', md: 'flex' },
                  paddingLeft: '3rem',
                }}
              >
                {categoriasMenu
                  .filter(c => c.level === 1)
                  .map(cat => {
                    return (
                      <MenuListBottom key={cat.id} menu={cat}></MenuListBottom>
                    )
                  })}
              </Box>
            </Toolbar>
          </Container>
        </div>
      </AppBar>
    </BlockUi>
  )
}
