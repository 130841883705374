import { swallInfo, swallType } from '../swall'

import axios from 'axios'

type ITipo = 'get' | 'post' | 'put' | 'delete'
interface IDataAxios {
  tipo: ITipo
  passed_url: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any
}
class AxiosFetch {
  public async execute({
    tipo,
    passed_url,
    data,
    config,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IDataAxios): Promise<any> {
    const url = this.prodVsDev(passed_url)
    switch (tipo) {
      case 'get':
        return this.get(url, config)
      case 'post':
        return this.post(url, data, config)
      case 'put':
        return this.put(url, data)
      case 'delete':
        return this.delete(url)
      default:
        swallInfo('ERRO', 'fetch data type not found!', swallType.error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async get(url: string, config: any): Promise<any> {
    let result = null
    await axios
      .get(url, config)
      .then(response => (result = response.data))
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          swallInfo(
            'ERRO',
            error.response.data || error.response.data.message,
            swallType.error,
          )
          return
        } else if (error.request) {
          // The request was made but no response was received
          swallInfo('ERRO', error.request, swallType.error)
          return
        } else {
          // Something happened in setting up the request that triggered an Error
          swallInfo('ERRO', error.message, swallType.error)
          return
        }
      })
    return result
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async post(url: string, data: any, config: any): Promise<any> {
    let result = null
    await axios
      .post(url, data, config)
      .then(response => (result = response.data))
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          swallInfo('ERRO', error.response.data.message, swallType.error)
        } else if (error.request) {
          // The request was made but no response was received
          swallInfo('ERRO', error.request, swallType.error)
        } else {
          // Something happened in setting up the request that triggered an Error
          swallInfo('ERRO', error.message, swallType.error)
        }
      })
    return result
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async put(url: string, data: any): Promise<any> {
    let result = null
    await axios
      .put(url, data)
      .then(response => (result = response.data))
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          swallInfo('ERRO', error.response.data.message, swallType.error)
        } else if (error.request) {
          // The request was made but no response was received
          swallInfo('ERRO', error.request, swallType.error)
        } else {
          // Something happened in setting up the request that triggered an Error
          swallInfo('ERRO', error.message, swallType.error)
        }
      })
    return result
  }

  private async delete(url: string): Promise<void> {
    await axios.delete(url).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        swallInfo('ERRO', error.response.data.message, swallType.error)
      } else if (error.request) {
        // The request was made but no response was received
        swallInfo('ERRO', error.request, swallType.error)
      } else {
        // Something happened in setting up the request that triggered an Error
        swallInfo('ERRO', error.message, swallType.error)
      }
    })
  }

  prodVsDev(passed_url: string) {
    const url =
      process.env.REACT_APP_PROD === 'DEV'
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL
    return `${url}${passed_url}`
  }
}

export default AxiosFetch
