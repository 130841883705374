import './styles.css'
import '../../css/font-awesome.css'
interface IProps {
  stars: number
}

export const StarRating = ({ stars }: IProps) => {
  stars = 5
  return (
    <div className='stars-container'>
      {Array.from({ length: stars }, (_, i) => (
        <p key={i}>
          <span key={i}>
            <i className='stars fa fa-star'></i>
          </span>
        </p>
      ))}
    </div>
  )
}
