import '../../../../css/contactos.css'

import { Component } from './component'

export const Contactos = () => {
  return (
    <div id='contactos' className='contactos-seccao'>
      <div className='contactos-texto-header'>
        <h2 className='h2'>Contactos </h2>
      </div>
      <Component />
    </div>
  )
}
