import { IYouTubeVideos } from '../../../../../config/types'
import YouTube from 'react-youtube'

interface IProps extends IYouTubeVideos {
  size: number
}

export const YouTubeVideo = ({ id, titulo, videoId, size = 1 }: IProps) => {
  const options = {
    height: 250 * size,
    width: 350 * size,
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  }

  const _onReady = (event: { target: { pauseVideo: () => void } }) => {
    event.target.pauseVideo()
  }

  return (
    <>
      <YouTube
        videoId={videoId}
        opts={options}
        title={titulo}
        onReady={_onReady}
        key={id}
      />
    </>
  )
}
