import { getCategories, getProductList } from '../../utils/data/getData'
import { useAppDispatch, useAppSelector } from '../../app/hooks'

import { Body } from './body'
import { Header } from './body/header'
import Loading from '../../utils/components/loading'
import ScrollToTop from '../../utils/components/scrollToTop'
import { loading as loadingCategories } from '../../app/features/category/category-slicer'
import { loading as loadingProducts } from '../../app/features/product/product-slicer'
import { useEffect } from 'react'

export const LopStore = () => {
  const dispatch = useAppDispatch()
  /*   dispatch(clearProducts())
  dispatch(clearCategories()) */

  const { estado: estadoCategorias, dados: categorias } = useAppSelector(
    state => state.categories,
  )

  const { estado: estadoProdutos, dados: produtos } = useAppSelector(
    state => state.products,
  )

  useEffect(() => {
    const getData = async () => {
      if (estadoCategorias === 'idle') {
        const data = await getCategories()
        dispatch(loadingCategories({ dados: data, estado: 'success' }))
      }
    }
    getData()
  }, [estadoCategorias])

  useEffect(() => {
    const getData = async () => {
      if (estadoProdutos === 'idle') {
        const data = await getProductList()
        dispatch(loadingProducts({ dados: data, estado: 'success' }))
      }
    }
    getData()
  }, [estadoProdutos])

  if (
    estadoCategorias === 'loading' ||
    estadoCategorias === 'idle' ||
    estadoProdutos === 'loading' ||
    estadoProdutos === 'idle'
  ) {
    return <Loading />
  } else if (!categorias?.length || !produtos?.length) {
    return <p>Erro ao carregar Categorias</p>
  } else {
    return (
      <>
        <ScrollToTop />
        <Header categorias={categorias} />
        <Body />
      </>
    )
  }
}
