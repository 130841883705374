import '../../../../css/lopParceiros.css'

import React, { useEffect, useState } from 'react'

import parceiros from '../../../../config/urlConfigParceiros.json'

const AutoSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const images = parceiros.parceiros
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex === images.length - 1) {
          return 0 // Reset to the first image
        }
        return prevIndex + 1 // Increment to the next image
      })
    }, 2000) // Change image every 3 seconds

    return () => {
      clearInterval(intervalId) // Cleanup on component unmount
    }
  }, [])

  return (
    <a href='/lop_parceiros' title='Lop Parceiros'>
      <div className='slide-show-image-slider'>
        <div
          className='slide-show-slider-content'
          style={{
            transform: `translateX(-${currentIndex * 8}rem)`, // Adjust for horizontal slide
            transition: 'transform 2s linear', // CSS transition for smooth slide
          }}
        >
          {images.map(({ url }, index) => (
            <img
              key={index}
              src={url}
              alt={`Slide ${index}`}
              className='slide-show-slide'
            />
          ))}
        </div>
      </div>
    </a>
  )
}

export default AutoSlider
