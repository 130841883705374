import '../../../../css/lopParceiros.css'

import { Body } from './component'
import { Header } from '../header'
import ScrollToTop from '../../../../utils/components/scrollToTop'

export const LopParceiros = () => {
  return (
    <>
      <ScrollToTop />
      <Header origin='lopParceiros' imageOffset={10} />
      <Body />
    </>
  )
}
