import {
  Category,
  LopFile,
  MacBookAirConfiguration,
  Product,
  User,
  shared,
} from '@linkdesign.pt/loptypes'

import AxiosFetch from './axiosFetch'

const axiosFetch = new AxiosFetch()

const getConfigToken = () => {
  const userFromLocalStorage = localStorage.getItem('user')
  if (!userFromLocalStorage) return
  const user = JSON.parse(userFromLocalStorage)
  const token = user['userToken']
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }
  return config
}

const getConfigApi = () => {
  const apikey = process.env.REACT_APP_ID
  const config = {
    headers: {
      apikey,
    },
  }
  return config
}

// * USER FUNCTIONS
const getUsersList = async (): Promise<
  shared.IWithObservationsAndFilesResponse<User.Entity>[]
> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: 'user',
    config: getConfigToken(),
  })
  return result
}

// * PRODUCT FUNCTIONS
const getProductList = async (): Promise<Product.IFindProductResponse[]> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `product`,
    config: getConfigApi(),
  })
  return result
}

const getProductById = async (
  id: string,
): Promise<Product.IFindProductResponse> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `product/${id}`,
    config: getConfigApi(),
  })
  return result
}

const getProductListByCategory = async (
  catId: string,
): Promise<Product.IFindProductResponse[]> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `product/listByCategory/${catId}`,
    config: getConfigApi(),
  })
  return result
}

const getCategories = async (): Promise<
  Category.ICategoryResponseWithCount[]
> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `category`,
    config: getConfigApi(),
  })
  return result
}

const getCategoriesMinified = async (): Promise<
  Category.ICategoryResponseMinified[]
> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `category/find_all_minified`,
    config: getConfigApi(),
  })
  return result
}

const getCategory = async (id: string): Promise<Category.Entity> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `category/${id}`,
    config: getConfigApi(),
  })
  return result
}

// * IMAGES FUNCTIONS
const getImagesByOwnerID = async (id: string): Promise<LopFile.Entity[]> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `lop_file/onwer_id/${id}`,
    config: getConfigToken(),
  })
  return result
}
// * MAC BOOK AIR  FUNCTIONS
const getAllMackBooks = async (): Promise<MacBookAirConfiguration.Entity[]> => {
  const result = await axiosFetch.execute({
    tipo: 'get',
    passed_url: `mac_book_air_configuration`,
    config: getConfigApi(),
  })
  return result
}
export {
  getUsersList,
  getProductList,
  getProductListByCategory,
  getProductById,
  getCategories,
  getCategoriesMinified,
  getCategory,
  getImagesByOwnerID,
  getAllMackBooks,
}
