import './styles.css'

import * as React from 'react'

import {
  CreditCard,
  CurrencyEur,
  PaypalLogo,
  Trash,
} from '@phosphor-icons/react'
import { swallInfo, swallType } from '../../../utils/swall'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'

import { Button } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { StoreItem } from './storeItem'
import { changeDrawer } from '../../../app/features/configuration/configuration-slicer'
import { clearCart } from '../../../app/features/shoppingCart/shoppingCart-slicer'
import { clearList } from '../../../app/features/wishList/wishList-slicer'
import { useEffect } from 'react'

interface IProductsWithPrices {
  id: string
  quantity: number
  price: number
}

export const TemporaryDrawer = () => {
  const [state, setState] = React.useState({
    right: false,
  })
  const dispatch = useAppDispatch()
  const { drawerOpen, drawerType } = useAppSelector(
    drawer => drawer.configuration,
  )

  const { shoppingCartItems } = useAppSelector(drawer => drawer.shoppingCart)
  const { whishListItems } = useAppSelector(drawer => drawer.wishList)

  useEffect(() => {
    setState({ ...state, ['right']: drawerOpen! })
  }, [drawerOpen])

  const toggleDrawer =
    (anchor: 'right', open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      console.log('Event =>', event as React.MouseEvent)

      dispatch(changeDrawer({ drawerOpen: !state.right, drawerType: null }))
      setState({ ...state, [anchor]: open })
    }

  const deleteData = () => {
    drawerType === 'shoppingCart'
      ? dispatch(clearCart())
      : dispatch(clearList())
    dispatch(changeDrawer({ drawerOpen: false, drawerType: null }))
    toggleDrawer('right', false)
  }

  const checkout = () => {
    toggleDrawer('right', false)
    swallInfo(
      'Checkout',
      `Aqui o cliente vai pagar ${totalRound}`,
      swallType.success,
    )
  }

  // ************** calcula o valor total************

  const { dados: produtos } = useAppSelector(state => state.products)

  const productsWitPrices: IProductsWithPrices[] = [
    {
      id: '',
      quantity: 0,
      price: 0,
    },
  ]
  if (!produtos?.length) return <p>erro as carregar Produtos</p>
  drawerType === 'shoppingCart'
    ? shoppingCartItems?.map(a => {
        const p = produtos.find(p => p.id === a.id)
        if (p) {
          productsWitPrices.push({
            id: a.id,
            quantity: a.quantity,
            price: p?.ProductPrices[0].price_sale || 0,
          })
        }
      })
    : whishListItems?.map(a => {
        const p = produtos.find(p => p.id === a.id)
        if (p) {
          productsWitPrices.push({
            id: a.id,
            quantity: a.quantity,
            price: p?.ProductPrices[0].price_sale || 0,
          })
        }
      })

  const total = productsWitPrices.reduce(
    (sum, acc) => acc.price * acc.quantity + sum,
    0,
  )

  const totalRound = `${(Math.round(total * 100) / 100).toFixed(2)} €`

  const list = (anchor: 'right') => (
    <div
      className='store-panel-base'
      role='presentation'
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className='store-panel-type-container'>
        <div className='store-panel-type-text-button-container'>
          <div className='store-panel-type-text-container'>
            <h4>{drawerType}</h4>
          </div>
          <div className='store-panel-type-button-container'>
            <Button onClick={deleteData}>
              <Trash size={24} />
            </Button>
          </div>
        </div>

        <div className='store-panel-type-value'>
          <h6>
            <strong>Total: </strong>
            {totalRound}
          </h6>
        </div>
        <div className='store-panel-type-payments'>
          <Button onClick={checkout}>
            <PaypalLogo size={24} />
            <CurrencyEur size={24} />
            <CreditCard size={24} />
          </Button>
        </div>
      </div>

      {/* LIST */}
      <div>
        <List>
          {drawerType === 'shoppingCart'
            ? shoppingCartItems?.map(product => (
                <ListItem>
                  <StoreItem
                    key={product.id}
                    product={product}
                    cartType={'shoppingCart'}
                  />
                </ListItem>
              ))
            : whishListItems?.map(product => (
                <ListItem>
                  <StoreItem
                    key={product.id}
                    product={product}
                    cartType={'wishList'}
                  />
                </ListItem>
              ))}
        </List>
      </div>
    </div>
  )

  return (
    <div>
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
