import urlConfigPages from '../../../../config/urlConfigPages.json'

export const Body = () => {
  const { lopCards } = urlConfigPages
  return (
    <>
      <div className='cards-seccao'>
        <div className='cards-texto'>
          <h3 dangerouslySetInnerHTML={{ __html: lopCards.texto }}></h3>
        </div>
        <div className='cards-button'>
          <a href='/lop_cards_selector' title='Lop Cards'>
            Lop Cards
          </a>
        </div>
      </div>
    </>
  )
}
