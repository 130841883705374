import './styles.css'

import { Category } from '@linkdesign.pt/loptypes'

interface IProps {
  productCategory: Category.Entity
}

export const HeaderProduct = ({ productCategory }: IProps) => {
  return (
    <div className='header-container'>
      <div className='left-side'>
        <a>{productCategory.name}</a>
      </div>
      <div className='right-side'>
        <a>Ver outros {productCategory.name}</a>
      </div>
    </div>
  )
}
