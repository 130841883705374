import './styles.css'

import { ICategoryResponseWithCountAndHref } from './navBottomStore'
import { useNavigate } from 'react-router-dom'

interface Iprops {
  menu: ICategoryResponseWithCountAndHref
}

export const MenuListBottom = ({ menu }: Iprops) => {
  const navigate = useNavigate()
  const handleClick = () => {
    !menu.href
      ? navigate(`/lop_store/category/${menu.id}`)
      : navigate(menu.href)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <button className='lop-nav-navbar-button' onClick={handleClick}>
      {menu.name}
    </button>
  )
}
