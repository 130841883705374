import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Category } from '@linkdesign.pt/loptypes'
import { IResponseOptions } from '../../types'

export interface categoryState {
  updated_at?:Date|null
  dados: Category.ICategoryResponseWithCount[]|null
  estado: IResponseOptions
}

const initialState: categoryState = {
  updated_at:null,
  dados: [],
  estado: 'idle',
}

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clear: state => {
      state.updated_at=null,
      state.estado = 'idle',
      state.dados = null
      return state

    },
    
    loading: (state, action: PayloadAction<categoryState>) => {
      state.updated_at=new Date(),
      state.estado = action.payload.estado,
      state.dados = action.payload.dados
      return state

    },
  },
})

export const { clear, loading } = categorySlice.actions

export default categorySlice.reducer
