import '../../../../css/quemSomos.css'

import urlConfigPages from '../../../../config/urlConfigPages.json'

export const QuemSomos = () => {
  return (
    <div id='quem_somos' className='quem-somos-seccao'>
      <div className='quem-somos-text-header'>
        <h2 className='h2'>Quem Somos</h2>
      </div>
      <div className='quem-somos-text'>
        <a
          dangerouslySetInnerHTML={{
            __html: urlConfigPages.quemSomos.texto,
          }}
        ></a>
      </div>
      <div className='quem-somos-mapa-container'>
        <img
          className='quem-somos-mapa'
          decoding='async'
          loading='lazy'
          src={'https://lop.pt/wp-content/uploads/2019/03/Lop_Mapa_01.png'}
          alt='mapa_lop'
        />
      </div>
    </div>
  )
}
