import './styles.css'

import { useAppDispatch, useAppSelector } from '../../../app/hooks'

import BlockUi from '@availity/block-ui'
import { BodyProduct } from './body'
import { Box } from '@mui/material'
import { HeaderProduct } from './header'
import { InfoProduct } from './info'
import { SimilarProduct } from './similar'
import { getProductList } from '../../../utils/data/getData'
import { loading } from '../../../app/features/product/product-slicer'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const StoreProduct = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()
  const { estado, dados: produtos } = useAppSelector(state => state.products)

  useEffect(() => {
    const getData = async () => {
      console.log('estado => ', estado)
      if (estado === 'idle') {
        const productsData = await getProductList()
        dispatch(loading({ dados: productsData, estado: 'success' }))
      }
    }
    getData()
  }, [])

  if (!produtos?.length) return <p>'Produtos não encontrados!'</p>
  const produto = produtos.find(produto => produto.id === id)
  if (!produto) return <p>'Produto não encontrado!'</p>
  return (
    <>
      <Box className='store-product'>
        <BlockUi tag='div' blocking={estado !== 'success'}>
          <HeaderProduct
            key={produto.Category.id}
            productCategory={produto.Category}
          />
          <BodyProduct product={produto} />

          <InfoProduct info={produto.description} />
          <SimilarProduct
            key={produto.id}
            productId={produto.id}
            productCategoryId={produto.Category.id}
          />
        </BlockUi>
      </Box>
    </>
  )
}
