import {
  LopFile,
  Product,
  User,
  WebSiteMessageFromClient,
  shared,
} from '@linkdesign.pt/loptypes'
import { swallInfo, swallType } from '../swall'

import AxiosFetch from './axiosFetch'
import axios from 'axios'

const axiosFetch = new AxiosFetch()
const getConfigToken = () => {
  const userFromLocalStorage = localStorage.getItem('user')
  if (!userFromLocalStorage) return
  const user = JSON.parse(userFromLocalStorage)
  const token = user['userToken']
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }
  return config
}
const getConfigApi = () => {
  const apikey = process.env.REACT_APP_ID
  const config = {
    headers: {
      apikey,
    },
  }
  return config
}
// * USER FUNCTIONS
const login = async (
  data: User.ILoginRequest,
): Promise<User.ILoginResponse | null> => {
  const result = await axiosFetch.execute({
    tipo: 'post',
    passed_url: `user/login`,
    data,
  })
  return result
}

const createUser = async (
  data: User.ICreateRequest,
): Promise<User.Entity[]> => {
  const result = await axiosFetch.execute({
    tipo: 'post',
    passed_url: 'user/',
    config: getConfigToken(),
    data,
  })
  return result
}

// * UPLOAD FILE

const createLopFile = async (
  id: shared.IFindByOwnerIdRequest,
  data: LopFile.ICreateRequest,
): Promise<LopFile.Entity> => {
  const result = await axiosFetch.execute({
    tipo: 'post',
    passed_url: `lop_file/${id.owner_id}`,
    config: getConfigToken(),
    data,
  })
  return result
}

const uploadSingleFile = async (data: {
  file: File
}): Promise<string | null> => {
  let result = null
  const bodyFormData = new FormData()
  bodyFormData.append('image', data.file)

  await axios({
    method: 'post',
    url: `https://api.imgbb.com/1/upload?key=9bfc2a26b400629f09f20a4fd3e1888f`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(response => (result = response.data))
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        swallInfo('ERRO', error.response.data.message, swallType.error)
      } else if (error.request) {
        // The request was made but no response was received
        swallInfo('ERRO', error.request, swallType.error)
      } else {
        // Something happened in setting up the request that triggered an Error
        swallInfo('ERRO', error.message, swallType.error)
      }
    })
  return result
}

// * PRODUCT

const createProduct = async (
  data: Product.ICreateRequest,
): Promise<Product.Entity[]> => {
  const result = await axiosFetch.execute({
    tipo: 'post',
    passed_url: `product`,
    data,
  })
  return result
}

const updateProduct = async (
  { id }: shared.IFindByIdRequest,
  data: Product.IUpdateRequest,
): Promise<Product.Entity[]> => {
  const result = await axiosFetch.execute({
    tipo: 'put',
    passed_url: `product/${id}`,
    data,
  })
  return result
}

// * CLIENT CONTACT

const createClientMessageFromWebsite = async (
  data: WebSiteMessageFromClient.ICreateRequest,
): Promise<string> => {
  try {
    const result = await axiosFetch.execute({
      tipo: 'post',
      passed_url: `web_site_message_from_client`,
      config: getConfigApi(),
      data,
    })
    return result
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    swallInfo('ERRO', error, swallType.error)
    return 'Erro'
  }
}

// * UPLOAD FILES

const uploadProductFile = async (data: File): Promise<void> => {
  const config = getConfigApi()
  const bodyFormData = new FormData()
  bodyFormData.append('file', data)
  const url = new AxiosFetch()
  await axios({
    method: 'post',
    url: url.prodVsDev(`product/product_file`),
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      apikey: config.headers.apikey,
    },
  }).catch(function (error) {
    if (error.response) {
      // Request made and server responded
      swallInfo('ERRO', error.response.data.message, swallType.error)
      return
    } else if (error.request) {
      // The request was made but no response was received
      swallInfo('ERRO', error.request, swallType.error)
      return
    } else {
      // Something happened in setting up the request that triggered an Error
      swallInfo('ERRO', error.message, swallType.error)
      return
    }
  })
}

export {
  login,
  createUser,
  uploadSingleFile,
  createLopFile,
  createProduct,
  updateProduct,
  createClientMessageFromWebsite,
  uploadProductFile,
}
