import { Button, TextField } from '@mui/material'

import { uploadProductFile } from '../../utils/data/sendData'
import { useState } from 'react'

export default function ImportProducts() {
  const [file, setFile] = useState<File | null>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = async (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  async function handleSubmitFile() {
    try {
      if (!file) return
      await uploadProductFile(file)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p>Upload de ficheiro de produtos</p>
      <form>
        <TextField
          type='file'
          onChange={event => handleFileChange(event)}
          style={{ paddingBottom: '1rem', width: '20rem' }}
        />
        <Button
          style={{ display: 'flex', flexDirection: 'column', width: '10rem' }}
          variant='contained'
          color='primary'
          component='div'
          disabled={!file}
          onClick={handleSubmitFile}
        >
          Upload
        </Button>
      </form>
    </div>
  )
}
