import urlConfigLandingPage from '../../../../config/urlConfigPages.json'

interface IProps {
  origin: string
  imageOffset: number
}

export const Header = ({ origin, imageOffset }: IProps) => {
  const { seccoes } = urlConfigLandingPage
  const url = seccoes.find(sec => sec.id === origin)
  if (!url) return null

  return (
    <div
      className='landing-page-header'
      style={{
        height: '35vh',
        backgroundPosition: `50% calc(50% + ${imageOffset}px)`,
        backgroundRepeat: 'norepeat',
        backgroundSize: 'cover',

        backgroundImage: `url(${url.section_url})`,
      }}
    ></div>
  )
}
