import '../../../../css/projectos.css'

import { LopItem } from './lopItem'
import { ProjectComponent } from '../../../../utils/components/project_component'
import urlConfigBase from '../../../../config/urlConfigBase.json'
import urlConfigLandingPage from '../../../../config/urlConfigLandingPage.json'
import urlConfigPages from '../../../../config/urlConfigPages.json'

export const ProjectBody = () => {
  const { Projecto } = urlConfigPages
  return (
    <>
      <div className='projectos-texto'>
        <h4>{Projecto.texto1}</h4>
        <h5>{Projecto.texto2}</h5>
      </div>
      <div className='projectos-lop-logos'>
        {Projecto.lopItems.map(item => {
          const image_url =
            urlConfigBase.Icons.find(icon => icon.name === item.name)
              ?.image_url ||
            process.env.REACT_APP_NO_IMAGE_THUMB ||
            ''
          return (
            <LopItem
              image={image_url}
              text={item.text}
              name={item.name}
              link_url={item.link_url}
            />
          )
        })}
      </div>
      <div className='projetos-container'>
        {urlConfigLandingPage.projetos.slice(1).map(project => (
          <ProjectComponent id={project.id} key={project.id} />
        ))}
      </div>
    </>
  )
}
