import '../../css/projectos.css'

import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import urlConfigLandingPage from '../../config/urlConfigLandingPage.json'

interface IProps {
  id: number
}

export const ProjectComponent = ({ id }: IProps) => {
  const { projetos } = urlConfigLandingPage

  const projectoItem = projetos.find(item => item.id === id)
  if (!projectoItem) return null
  return (
    <div className='projectos-item'>
      <a href={projectoItem.url}>
        <ImageListItem key={projectoItem.id}>
          <img
            srcSet={`${projectoItem.imageUrl}`}
            src={`${projectoItem.imageUrl}`}
            alt={projectoItem.text}
            loading='lazy'
          />
          <ImageListItemBar
            title={
              <span
                className='projectos-texto-item-bar'
                dangerouslySetInnerHTML={{ __html: projectoItem.text }}
              />
            }
            position='bottom'
          />
        </ImageListItem>
      </a>
    </div>
  )
}
