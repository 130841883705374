import urlConfigPages from '../../../../config/urlConfigPages.json'
export const Body = () => {
  const { lopRetail } = urlConfigPages
  return (
    <>
      <div className='retail-seccao'>
        <div className='retail-texto'>
          <h3 dangerouslySetInnerHTML={{ __html: lopRetail.texto }}></h3>
        </div>
      </div>
    </>
  )
}
