import '../../../../css/lopParceiros.css'

import urlConfigParceiros from '../../../../config/urlConfigParceiros.json'

export const Body = () => {
  const { parceiros } = urlConfigParceiros
  return (
    <div style={{ paddingTop: '5rem', paddingInline: '10rem' }}>
      {parceiros.map(parceiro => {
        return (
          <img src={parceiro.url} style={{ height: '8rem', padding: '1rem' }} />
        )
      })}
    </div>
  )
}
