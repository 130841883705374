import './styles.css'
import '../../css/main.css'

export const Footer = () => {
  return (
    <div className='footer-seccao'>
      <a className='footer-text'>© Lop - Surprise the World</a>
      <div className='footer-text'>
        <div className='footer-politicas'>
          <a href='https://lop.pt/protecao-de-dados/#PP'>
            Política de Privacidade
          </a>
          |
          <a href='https://lop.pt/protecao-de-dados/#RLO'>
            Resolução de Litígios Online
          </a>
          |
          <a href='https://lop.pt/protecao-de-dados/#PC'>Política de Cookies</a>
        </div>
      </div>

      <div>
        <a href='https://lop.pt/portugal2020/'>
          <span>
            <img
              decoding='async'
              loading='lazy'
              src='https://lop.pt/wp-content/uploads/2019/04/pt20202.png'
              alt=''
              title=''
              height='auto'
              width='auto'
              srcSet='https://lop.pt/wp-content/uploads/2019/04/pt20202.png 330w, https://lop.pt/wp-content/uploads/2019/04/pt20202-300x27.png 300w'
              sizes='(max-width: 330px) 100vw, 330px'
            />
          </span>
        </a>
        <span>
          <img
            decoding='async'
            loading='lazy'
            src='https://lop.pt/wp-content/uploads/2019/01/certificado-ssl-pt.png'
            alt=''
            title=''
            height='auto'
            width='auto'
          />
        </span>
      </div>
    </div>
  )
}
