import { IWishList, IWishListState } from './wishList-slicer'

import { PayloadAction } from '@reduxjs/toolkit'
import { toastNotify } from '../../../utils/toast'

export const addToWishList = (
  state: IWishListState,
  action: PayloadAction<IWishList>,
) => {
  // não existem artigos no carrinho
  if (!state.whishListItems) {
    toastNotify({
      text: 'artigo criado na wish list.',
      type: 'success',
      toastId: 'wishCreate',
    })
    return {
      ...state,
      whishListItems: [{ ...action.payload }],
    }
  }
  // existe já este artigo no carrinho
  const existInCart = state.whishListItems.some(
    product => product.id === action.payload.id,
  )

  if (existInCart) {
    toastNotify({
      text: 'artigo atualizado da wish list.',
      type: 'info',
      toastId: 'wishUpdate',
    })

    return {
      ...state,
      whishListItems: state.whishListItems.map(product =>
        product.id === action.payload.id
          ? {
              ...product,
              quantity: product.quantity + 1,
            }
          : product,
      ),
    }
  }

  return {
    ...state,
    whishListItems: [...state.whishListItems, { ...action.payload }],
  }
}

export const decreaseFromWishList = (
  state: IWishListState,
  action: PayloadAction<IWishList>,
) => {
  if (!state.whishListItems) return
  toastNotify({
    text: 'artigo atualizado na wish list.',
    type: 'info',
    toastId: 'wishUpdate',
  })
  return {
    ...state,
    whishListItems: state.whishListItems
      .map(product =>
        product.id === action.payload.id
          ? {
              ...product,
              quantity: product.quantity - 1,
            }
          : product,
      )
      .filter(product => product.quantity > 0),
  }
}

export const removeFromWishList = (
  state: IWishListState,
  action: PayloadAction<IWishList>,
) => {
  if (!state.whishListItems) return
  toastNotify({
    text: 'artigo removido da wish list.',
    type: 'info',
    toastId: 'wishRemove',
  })
  return {
    ...state,
    whishListItems: state.whishListItems.filter(
      product => product.id !== action.payload.id,
    ),
  }
}
