import './styles.css'

import Badge, { BadgeProps } from '@mui/material/Badge'
import {
  BagSimple,
  Browsers,
  Heart,
  SignIn,
  SignOut,
  User,
} from '@phosphor-icons/react'
import {
  changeDrawer,
  openLogin,
  setUser,
} from '../../app/features/configuration/configuration-slicer'
import { useAppDispatch, useAppSelector } from '../../app/hooks'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

interface IProps {
  lopStore: boolean
}

export const NavTop = ({ lopStore }: IProps) => {
  const { shoppingCartItems } = useAppSelector(state => state.shoppingCart)
  const { whishListItems } = useAppSelector(state => state.wishList)
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      color: 'black',
      fontSize: 10,
      backgroundColor: '#4ac4eb;',
      right: 2,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))
  const { userName, userType } = useAppSelector(state => state.configuration)
  console.log(userName)
  const handleOpenLogin = () => {
    dispatch(openLogin())
  }

  const handleShoppingCart = () => {
    dispatch(changeDrawer({ drawerOpen: true, drawerType: 'shoppingCart' }))
  }

  const handleWhishList = () => {
    dispatch(changeDrawer({ drawerOpen: true, drawerType: 'wishList' }))
  }

  const handleLogout = () => {
    setIsOpen(false)
    dispatch(
      setUser({
        userName: undefined,
        userToken: undefined,
        userType: undefined,
      }),
    )
  }
  if (lopStore) {
    return (
      <AppBar position='static' className='lop-nav-top-header' elevation={0}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters style={{ top: '-10px' }}>
            <a href='/'>
              <img
                className='lop-nav-top-logo-image'
                src={
                  'https://lop.pt/wp-content/uploads/2019/03/Lop_Logo_03.png'
                }
              />
            </a>
            {/* // *** pc *** */}
            <Box sx={{ flexGrow: 2 }}>
              <textarea className='store-text-area' />
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                minWidth: '10rem',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title='Carrinho de compras'>
                <IconButton aria-label='cart' onClick={handleShoppingCart}>
                  <StyledBadge badgeContent={shoppingCartItems?.length || 0}>
                    <BagSimple size={24} />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Tooltip title='Lista de desejos'>
                <IconButton aria-label='wishList' onClick={handleWhishList}>
                  <StyledBadge badgeContent={whishListItems?.length || 0}>
                    <Heart size={24} />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              {!userName ? (
                <Tooltip title='Login'>
                  <IconButton aria-label='login' onClick={handleOpenLogin}>
                    <SignIn size={24} />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <IconButton
                    aria-label='login'
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <User size={24} color='#4ac4eb' />
                  </IconButton>
                  {isOpen && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 2500,
                        position: 'absolute',
                        top: '40px',
                        right: '0',
                        padding: '0.5rem',
                        backgroundColor: 'whitesmoke',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton
                          aria-label='Logout'
                          onClick={handleLogout}
                          style={{ height: '1rem' }}
                        >
                          <SignOut size={24} color='#4ac4eb' />
                          <p style={{ fontSize: '0.6rem' }}>Logout</p>
                        </IconButton>
                      </div>
                      {userType === 'ADMINISTRATOR' && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '0.5rem',
                          }}
                        >
                          <IconButton
                            style={{ height: '2rem' }}
                            aria-label='login'
                            onClick={() => {
                              setIsOpen(false)
                              navigate('/backoffice')
                            }}
                          >
                            <Browsers size={24} color='#4ac4eb' />
                            <p style={{ fontSize: '0.6rem' }}>Admin Page</p>
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    )
  } else {
    return (
      <AppBar position='static' className='lop-nav-top-header' elevation={0}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters style={{ top: '-10px' }}>
            <a href='/'>
              <img
                className='lop-nav-top-logo-image'
                src={
                  'https://lop.pt/wp-content/uploads/2019/03/Lop_Logo_03.png'
                }
              />
            </a>
          </Toolbar>
        </Container>
      </AppBar>
    )
  }
}
