import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import AsyncStorage from '@react-native-async-storage/async-storage'
import categoryReducer from './features/category/category-slicer'
import configurationReducer from './features/configuration/configuration-slicer'
import productReducer from './features/product/product-slicer'
import shoppingCartReducer from './features/shoppingCart/shoppingCart-slicer'
import wishListReducer from './features/wishList/wishList-slicer'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  //blacklist: ['categoryReducer', 'productReducer'],
}

const rootReducer = combineReducers({
  categories: categoryReducer,
  products: productReducer,
  shoppingCart: shoppingCartReducer,
  wishList: wishListReducer,
  configuration: configurationReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.REACT_APP_PROD !== 'PROD',
})

export const persistor = persistStore(store)
