export const ContactInfo = () => {
  return (
    <div className='contactos-info'>
      <h3>Let´s Keep in Touch</h3>
      <h5>Para mais informações não hesite em contactar-nos.</h5>
      <hr />
      <div className='contactos-separator'>
        {/* LISBOA */}
        <div className='contactos-container'>
          <h4>Lisboa</h4>
          <div className='contactos-item'>
            <i className='fa fa-home'></i> Rua Pedro e Inês Lote 3, Piso 2 –
            Escritório 11 1990 – 074 Lisboa
          </div>
          <div className='contactos-item'>
            <i className='fa fa-phone'></i> (+351) 217 274 030
          </div>
          <div className='contactos-item'>
            <i className='fa fa-envelope'></i>
            <a href='mailto:geral@lop.pt'> geral@lop.pt</a>
          </div>
        </div>
      </div>
    </div>
  )
}
