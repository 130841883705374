import './styles.css'

import { useEffect, useState } from 'react'

import Loading from '../../utils/components/loading'
import { MacBookAirConfiguration } from '@linkdesign.pt/loptypes'
import { ProductsTable } from './utils/tables/products'
import { getAllMackBooks } from '../../utils/data/getData'

export const MacBookConfigurator = () => {
  const [macBooks, setMacBooks] = useState<MacBookAirConfiguration.Entity[]>([])

  useEffect(() => {
    const getMacBooks = async () => {
      const result = await getAllMackBooks()
      if (!result.length) {
        throw new Error('Erro ao obter a listagem de macBooksPro!')
      }
      setMacBooks(result)
    }
    getMacBooks()
  }, [])

  if (!macBooks.length) return <Loading />

  return (
    <div className='mac-book-body-container'>
      <video
        autoPlay
        loop
        src='https://www.apple.com/105/media/us/mac/family/2023/1b2bbf5c-ddc5-44a1-9dfb-7a51c49143fa/anim/welcome/xlarge.mp4'
      ></video>
      <div className='mac-book-body-table'>
        <ProductsTable macBooks={macBooks} />
      </div>
    </div>
  )
}
