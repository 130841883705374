import { YouTubeVideo } from '../mister_gadget/youtTubeVideos/component'
import urlConfigPages from '../../../../config/urlConfigPages.json'

export const Body = () => {
  const { lopPoint } = urlConfigPages
  return (
    <>
      <div className='point-seccao'>
        <div className='point-texto'>
          <p dangerouslySetInnerHTML={{ __html: lopPoint.texto }}></p>
        </div>
        <YouTubeVideo
          id={1}
          grupo={'lopPoint'}
          titulo={lopPoint.texto}
          videoId={lopPoint.video_id}
          size={2}
        />
      </div>
    </>
  )
}
