import { getCategories, getProductList } from '../../../utils/data/getData'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'

import BlockUi from '@availity/block-ui'
import ScrollToTop from '../../../utils/components/scrollToTop'
import { StoreSection } from './sections'
import { loading as categoriesLoading } from '../../../app/features/category/category-slicer'
import { loading as productsLoading } from '../../../app/features/product/product-slicer'
import { useEffect } from 'react'

export const Body = () => {
  const dispatch = useAppDispatch()

  const { estado: estadoCategorias, dados: dadosCategorias } = useAppSelector(
    state => state.categories,
  )

  const { estado: estadoProdutos } = useAppSelector(state => state.products)

  useEffect(() => {
    const getData = async () => {
      if (estadoCategorias === 'idle') {
        const categoriesData = await getCategories()
        dispatch(
          categoriesLoading({
            dados: categoriesData,

            estado: 'success',
          }),
        )
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (estadoProdutos === 'idle') {
        const productsData = await getProductList()
        dispatch(
          productsLoading({
            dados: productsData,

            estado: 'success',
          }),
        )
      }
    }
    getData()
  }, [])
  return !dadosCategorias ? (
    <p>Erro</p>
  ) : (
    <BlockUi
      blocking={estadoCategorias !== 'success' || estadoProdutos !== 'success'}
    >
      <ScrollToTop />

      {dadosCategorias.map(category => {
        if (category.id && category.products_count) {
          return (
            <StoreSection
              key={category.name}
              sectionName={category.name}
              sectionDescription={category.name}
              categoryId={category.id}
              subCategory={false}
            />
          )
        }
      })}
    </BlockUi>
  )
}
