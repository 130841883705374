import './styles.css'

import { LandingPageSection } from './landingPageSection'

export const Header = () => {
  return (
    <div className='landing-page-container'>
      <div className='landing-page-section'>
        <LandingPageSection sectionNumber={1} />
      </div>
      <div className='landing-page-section'>
        <LandingPageSection sectionNumber={2} />
      </div>
      <div className='landing-page-section'>
        <LandingPageSection sectionNumber={3} />
      </div>
      <div className='landing-page-section'>
        <LandingPageSection sectionNumber={4} />
      </div>
    </div>
  )
}
