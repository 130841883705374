import './styles.css'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useEffect, useRef, useState } from 'react'

import BlockUi from '@availity/block-ui'
import { List } from './list'
import { ProductListItem } from '../productListItem'
import { Slider } from './slider'
import { getProductList } from '../../../../utils/data/getData'
import { loading as loadingProducts } from '../../../../app/features/product/product-slicer'
import { useNavigate } from 'react-router-dom'

interface IProps {
  sectionName: string
  sectionDescription: string | null
  categoryId: string
  subCategory: boolean
}

export const StoreSection = ({
  categoryId,
  sectionName,
  sectionDescription,
  subCategory,
}: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const sliderRef = useRef<HTMLInputElement>(null)
  const [width, setwidth] = useState(0)
  const { estado: estadoProdutos, dados: produtos } = useAppSelector(
    state => state.products,
  )

  useEffect(() => {
    const getData = async () => {
      if (estadoProdutos === 'idle') {
        const data = await getProductList()
        dispatch(loadingProducts({ dados: data, estado: 'success' }))
      }
    }
    getData()
  }, [estadoProdutos])

  const handleOpenSelectedCategory = () => {
    navigate(`/lop_store/selected_category/${categoryId}`)
  }

  if (!produtos?.length) return <p>Erro ao carregar Produtos</p>
  const produtosDaCategoria = produtos.filter(produto => {
    try {
      return produto.Category.id === categoryId
    } catch (error) {
      console.error(error)
    }
  })

  useEffect(() => {
    if (!sliderRef.current) return
    setwidth(sliderRef.current.clientWidth)
  })

  return (
    <BlockUi blocking={estadoProdutos !== 'success'}>
      <div className='store-section'>
        <span
          onClick={handleOpenSelectedCategory}
          className='store-section-text'
        >
          {sectionName} ({produtosDaCategoria.length})
        </span>
        <span className='store-section-secondary-text'>
          {' '}
          {sectionDescription}
        </span>
        {!subCategory ? (
          <Slider
            key={sectionName}
            parentSize={width}
            props={
              <div className='store-section-product' ref={sliderRef}>
                {produtosDaCategoria.map(produto => {
                  return (
                    <ProductListItem
                      key={produto.id}
                      product={produto}
                      size='large'
                    />
                  )
                })}
              </div>
            }
          />
        ) : (
          <List
            key={sectionName}
            Props={
              <div className='store-section-product-column'>
                {produtosDaCategoria.map(produto => {
                  return (
                    <ProductListItem
                      key={produto.id}
                      product={produto}
                      size='medium'
                    />
                  )
                })}
              </div>
            }
          />
        )}
      </div>
    </BlockUi>
  )
}
