import './styles.css'

export const HeaderInfoStore = () => {
  return (
    <>
      <div className='store-info-header'>
        {/* JUMBOTRON */}
        <div className='store-info-header-left'>
          <div className='store-info-header-jumbotron-container'>
            <img
              className='store-info-header-jumbotron-logo'
              src='https://lop.pt/wp-content/uploads/2019/03/Lop_AreasLogos_03.png'
              alt='lop Store Logo'
            />
            <span className='store-info-header-jumbotron-text '>
              Surpreenda o mundo.
            </span>
          </div>
        </div>

        {/* CONTACTO */}
        <div className='store-info-header-right'>
          <div className='store-info-header-contact-image-container'>
            <img
              className='store-info-header-contact-image'
              src='https://i.ibb.co/gFgLRP9/banner3.png'
              alt='contact_img'
            />
            <a
              href='tel:351217274030'
              className='store-info-header-contact-text'
            >
              Necessita de ajuda com as suas compras?
              <br />
              Ligue (+351) 217 274 030
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
