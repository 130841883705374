import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  addToShoppingCart,
  decreaseFromShoppingCart,
  removeFromShoppingCart,
} from './actions'

export interface IShoppingCart {
  id: string
  quantity: number
}

export interface IShoppingCartState {
  shoppingCartItems: IShoppingCart[] | null
}

// Define the initial state using that type
const initialState: IShoppingCartState = {
  shoppingCartItems: null,
}

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    clearCart: state => {
      state.shoppingCartItems = []
    },
    addToCart: (state, action: PayloadAction<IShoppingCart>) => {
      return addToShoppingCart(state, action)
    },
    decreaseFromCart: (state, action: PayloadAction<IShoppingCart>) => {
      return decreaseFromShoppingCart(state, action)
    },
    removeFromCart: (state, action: PayloadAction<IShoppingCart>) => {
      return removeFromShoppingCart(state, action)
    },
  },
})

export const { clearCart, addToCart, removeFromCart, decreaseFromCart } =
  shoppingCartSlice.actions

export default shoppingCartSlice.reducer
