import './styles.css'

interface IProps {
  info: string
}

export const InfoProduct = ({ info }: IProps) => {
  return (
    <div className='info-container'>
      <div className='info-inner-container'>
        <hr className='info-line' />
        <span className='info-title'>Informação do produto</span>
        <div className='info-text' style={{ overflow: 'auto' }}>
          <div dangerouslySetInnerHTML={{ __html: info }} />
        </div>
      </div>
    </div>
  )
}
