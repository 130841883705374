import './styles.css'

import BlockUi from '@availity/block-ui'
import { Category } from '@linkdesign.pt/loptypes'
import { useNavigate } from 'react-router-dom'

interface IProps {
  category: Category.Entity
}

export const IconSelector = ({ category }: IProps) => {
  const navigate = useNavigate()
  const handleOnClick = (category: Category.Entity) => {
    navigate(`/lop_store/category/${category.id}`)
  }
  return (
    <BlockUi tag='div' blocking={!category || !category.id}>
      <div className='store-icon-selector'>
        <a onClick={() => handleOnClick(category)}>
          <img
            className='store-icon-selector-image'
            src={`${category.icon_url}`}
            alt={category.name}
          />
          <p className='store-icon-selector-text'>{category.name}</p>
        </a>
      </div>
    </BlockUi>
  )
}
