import '../../../../css/projectos.css'

import { ProjectComponent } from '../../../../utils/components/project_component'
import urlConfigLandingPage from '../../../../config/urlConfigLandingPage.json'

export const Projectos = () => {
  const { projetos } = urlConfigLandingPage
  if (!projetos.length) return null
  return (
    <div className='projectos-seccao'>
      <div className='servicos-text-header'>
        <h2 className='h2'>Projetos</h2>
      </div>
      <div className='projectos-texto'>
        <h4 className='h4'>Adoramos o nosso trabalho.</h4>
      </div>
      <div className='projetos-container'>
        {projetos.slice(1).map(project => (
          <ProjectComponent id={project.id} key={project.id} />
        ))}
      </div>
      <div className='projectos-button'>
        <a href='/projectos' title='projectos'>
          Projectos
        </a>
      </div>
    </div>
  )
}
