import { Box, Typography } from '@mui/material'
import { Product, ProductAttribute } from '@linkdesign.pt/loptypes'

import { useAppSelector } from '../../app/hooks'
import { useNavigate } from 'react-router-dom'

interface IProps {
  productsAttributes: ProductAttribute.Entity[]
  designation: string
}

export const AttributeSelector = ({
  productsAttributes,
  designation,
}: IProps) => {
  const navigate = useNavigate()
  const products = useAppSelector(state => state.products)

  const handleProductChange = (id?: string) => {
    if (!id || !products?.dados) return

    const productId = products.dados.find(
      (product: Product.IFindProductResponse) =>
        product.ProductAttributes.find(pa => pa.id === id) &&
        product.designation === designation,
    )
    if (!productId?.id) return
    navigate(`/lop_store/product/${productId.id}`)
  }

  const colors = productsAttributes
    .filter(productsAttribute => productsAttribute.type === 'COLOR')
    .map(attribute => (
      <Box className='attribute-selector-size'>
        <Typography key={attribute.id} className='attribute-selector-size-text'>
          {attribute.value}
        </Typography>
      </Box>
    ))

  const size = productsAttributes
    .filter(productsAttribute => productsAttribute.type === 'SIZE')
    .map(attribute => (
      <Box
        className='attribute-selector-size'
        onClick={() => handleProductChange(attribute.id)}
      >
        <Typography key={attribute.id} className='attribute-selector-size-text'>
          {attribute.value}
        </Typography>
      </Box>
    ))

  return (
    <Box className='attribute-selector-container'>
      <Box className='attribute-selector'>{colors}</Box>
      <Box className='attribute-selector'>{size}</Box>
    </Box>
  )
}
