import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IResponseOptions } from '../../types'
import { Product } from '@linkdesign.pt/loptypes'

export interface ProductState {
  updated_at?:Date|null
  dados: Product.IFindProductResponse[]|null
  estado: IResponseOptions
}

const initialState: ProductState = {
  updated_at:null,
  dados: [],
  estado: 'idle',
}

export const ProductSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clear: state => {
      state.updated_at=null,
      state.estado = 'idle',
      state.dados = null
      return state

    },
    loading: (state, action: PayloadAction<ProductState>) => {
      state.updated_at=new Date(),
      state.estado = action.payload.estado,
      state.dados = action.payload.dados
      return state
}
  },
})

export const { clear, loading } = ProductSlice.actions

export default ProductSlice.reducer
