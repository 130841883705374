export const PageNotFound = () => {
  return (
    <>
      <a href='/'>
        <img
          src='https://i.ibb.co/sF5NSn7/page-Not-Found.png'
          alt='page-Not-Found'
        />
      </a>
    </>
  )
}

export const PageUnderConstruction = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{ height: '60vh' }}
        src='https://media.istockphoto.com/id/1283050796/pt/vetorial/flat-design-under-construction-concept.webp?s=2048x2048&w=is&k=20&c=tNf_zMxL2Kl4Gyc5Tb1ZPu1L9m5gzYyIZHVTHcVRc-c='
        alt='page-Not-Found'
      />
    </div>
  )
}
