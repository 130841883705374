import './styles.css'

import { useEffect, useRef, useState } from 'react'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
  parentSize: number
}

export const Slider = ({ props, parentSize }: IProps) => {
  const sliderRef = useRef<HTMLInputElement>(null)
  const scrollAmount = 350
  const [showButtons, setShowButtons] = useState(false)

  const handleScorllLeft = () => {
    const container = sliderRef.current
    if (!container) return null
    return (container.scrollLeft -= scrollAmount)
  }
  const handleScorllRight = () => {
    const container = sliderRef.current
    if (!container) return null
    return (container.scrollLeft += scrollAmount)
  }
  useEffect(() => {
    if (!sliderRef.current) return

    setShowButtons(parentSize > window.innerWidth)
  })

  return (
    <div className='slider-app'>
      <div
        className={
          showButtons
            ? 'button-show button-show-back'
            : 'button-show button-show-back hide'
        }
        onClick={() => handleScorllLeft()}
      >
        <button className='back-front-btn'>
          <ArrowBackIosIcon />
        </button>
      </div>
      <div className='slider-container' ref={sliderRef}>
        {props}
      </div>
      <div
        className={
          showButtons
            ? 'button-show button-show-front'
            : 'button-show button-show-front hide'
        }
        onClick={() => handleScorllRight()}
      >
        <button className='back-front-btn'>
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  )
}
