import { IShoppingCart, IShoppingCartState } from './shoppingCart-slicer'

import { PayloadAction } from '@reduxjs/toolkit'
import { toastNotify } from '../../../utils/toast'

export const addToShoppingCart = (
  state: IShoppingCartState,
  action: PayloadAction<IShoppingCart>,
) => {
  // não existem artigos no carrinho
  if (!state.shoppingCartItems) {
    return {
      ...state,
      shoppingCartItems: [{ ...action.payload }],
    }
  }
  // existe já este artigo no carrinho
  const existInCart = state.shoppingCartItems.some(
    product => product.id === action.payload.id,
  )

  if (existInCart) {
    toastNotify({
      text: 'artigo atualizado no carrinho de compras',
      type: 'info',
      toastId: 'cartUpdate',
    })
    return {
      ...state,
      shoppingCartItems: state.shoppingCartItems.map(product =>
        product.id === action.payload.id
          ? {
              ...product,
              quantity: product.quantity + 1,
            }
          : product,
      ),
    }
  }
  toastNotify({
    text: 'artigo criado no carrinho de compras',
    type: 'success',
    toastId: 'cartCreate',
  })
  return {
    ...state,
    shoppingCartItems: [...state.shoppingCartItems, { ...action.payload }],
  }
}

export const decreaseFromShoppingCart = (
  state: IShoppingCartState,
  action: PayloadAction<IShoppingCart>,
) => {
  if (!state.shoppingCartItems) return
  toastNotify({
    text: 'artigo atualizado no carrinho de compras',
    type: 'info',
    toastId: 'cartUpdate',
  })
  return {
    ...state,
    shoppingCartItems: state.shoppingCartItems
      .map(product =>
        product.id === action.payload.id
          ? {
              ...product,
              quantity: product.quantity - 1,
            }
          : product,
      )
      .filter(product => product.quantity > 0),
  }
}

export const removeFromShoppingCart = (
  state: IShoppingCartState,
  action: PayloadAction<IShoppingCart>,
) => {
  if (!state.shoppingCartItems) return
  toastNotify({
    text: 'artigo removido do carrinho de compras',
    type: 'info',
    toastId: 'cartUpdate',
  })

  return {
    ...state,
    shoppingCartItems: state.shoppingCartItems.filter(
      product => product.id !== action.payload.id,
    ),
  }
}
