/* eslint-disable @typescript-eslint/no-explicit-any */
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'

import { AgGridReact } from 'ag-grid-react'
import { Button } from '@mui/material'
import { MacBookAirConfiguration } from '@linkdesign.pt/loptypes'

interface IProps {
  macBooks: MacBookAirConfiguration.Entity[]
}

type IFields =
  | 'id'
  | 'image'
  | 'reference'
  | 'provider_reference'
  | 'designation'
  | 'original_price_sale'
  | 'price_sale'
interface IColumnDef {
  headerName: string
  field?: IFields

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellRenderer?: (params?: any) => JSX.Element
  sortable?: boolean
  filter?: boolean
  hide?: boolean
  flex?: number
  width?: number
}

const rowHeight = 200

const styles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  maxHeight: '100vh',
  maxWidth: '100vw',
  fontFamily: 'sans-serif',
  fontSize: '12px',
  fontWeight: 'normal',
}

export const ProductsTable = ({ macBooks }: IProps) => {
  const mbam1 =
    'https://www.apple.com/v/macbook-air-m1/f/images/overview/compare/compare_mba_13_15__bd3rjknohdxu_large.png'
  const mbam2 =
    'https://www.apple.com/v/macbook-air-13-and-15-m2/e/images/overview/routers/trade_in__f88qe6u6wpea_large.jpg'
  const gpu10 =
    'https://www.apple.com/v/macbook-air/q/images/overview/compare/compare_mba_15__fcvfhj9b1uy6_large.png'
  const listOfProducts = macBooks?.map(macBook => {
    const image =
      macBook.provider_reference === 'Mac Book Air M1'
        ? mbam1
        : macBook.provider_reference === 'GPU 10'
          ? gpu10
          : mbam2
    return {
      id: macBook.id,
      image,
      reference: macBook.reference,
      provider_reference: macBook.provider_reference,
      designation: macBook.designation,
      price_sale: Number(macBook.price_sale.toFixed(2)),
      original_price_sale: Number(macBook.original_price_sale.toFixed(2)),
      data: macBook.data,
    }
  })
  console.log(listOfProducts)
  if (!listOfProducts) return null

  const imageCell = (props: any) => {
    console.log(props.value)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: rowHeight,
          width: 'auto',
        }}
      >
        <img
          src={props.value}
          style={{
            height: 'auto',
            width: '100%',
            maxWidth: '100%',
          }}
        />
      </div>
    )
  }

  const butttonsCell = () => {
    return (
      <div style={styles}>
        <Button variant='contained'>Comprar</Button>
      </div>
    )
  }

  const poupanca = (props: any) => {
    return (
      <div style={styles}>
        <h3>
          {Math.round(
            (props.data.original_price_sale - props.data.price_sale) * 100,
          ) / 100}
        </h3>
      </div>
    )
  }
  const PrecoVenda = (props: any) => {
    return (
      <div style={styles}>
        <h3>{props.data.price_sale}</h3>
      </div>
    )
  }
  const Preco_original = (props: any) => {
    return (
      <div style={styles}>
        <h3>{props.data.original_price_sale}</h3>
      </div>
    )
  }
  const referencia = (props: any) => {
    return (
      <div style={styles}>
        <h3>{props.data.reference}</h3>
      </div>
    )
  }
  const designation = (props: any) => {
    return (
      <div
        style={{
          ...styles,
          maxWidth: '500px',
          overflowWrap: 'break-word',
          whiteSpace: 'pre-line',
        }}
      >
        <h3
          style={{
            maxWidth: '100%',
            overflowWrap: 'break-word',
          }}
        >
          {props.data.designation}
        </h3>
      </div>
    )
  }
  const referenciaFornecedor = (props: any) => {
    return (
      <div style={styles}>
        <h3>
          {Math.round(
            (props.data.original_price_sale - props.data.price_sale) * 100,
          ) / 100}
        </h3>
      </div>
    )
  }
  const productCell = (props: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Display:{props.data.data.display}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Chip:{props.data.data.chip}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Core:{props.data.data.core}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Battery Life:{props.data.data.battery_life}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Memory:{props.data.data.memory}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Storage:{props.data.data.storage}
        </p>
      </div>
    )
  }
  const productCell2 = (props: any) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          Size & Wheight:{props.data.data.size} x {props.data.data.weight}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          camera:{props.data.data.camera}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          audio:{props.data.data.audio}
        </p>
        <p
          style={{
            display: 'flex',
            lineHeight: '0.5rem',
            lineClamp: 'inherit',
          }}
        >
          ports:{props.data.data.ports}
        </p>
        <p style={{ display: 'flex', lineHeight: '0.5rem' }}>
          colors:{props.data.data.colors}
        </p>
      </div>
    )
  }

  const columnDefs: IColumnDef[] = [
    { headerName: 'ID', field: 'id', hide: true },
    {
      headerName: 'Imagem',
      field: 'image',
      cellRenderer: (props: any) => imageCell(props),
    },
    {
      headerName: 'Referencia',

      field: 'reference',
      sortable: true,
      filter: true,
      width: 100,
      cellRenderer: (props: any) => referencia(props),
    },
    {
      headerName: 'Referencia Fornecedor',
      field: 'provider_reference',
      sortable: true,
      filter: true,
      width: 100,
      cellRenderer: (props: any) => referenciaFornecedor(props),
    },
    {
      headerName: 'designation',
      sortable: true,
      flex: 2,
      cellRenderer: (props: any) => designation(props),
    },
    {
      headerName: 'Info',
      sortable: true,
      width: 165,
      cellRenderer: (props: any) => productCell(props),
    },
    {
      headerName: 'Info2',
      sortable: true,
      width: 250,
      cellRenderer: (props: any) => productCell2(props),
    },

    {
      headerName: 'Preço Lop',
      field: 'price_sale',
      sortable: true,
      width: 100,
      cellRenderer: (props: any) => PrecoVenda(props),
    },
    {
      headerName: 'Preço Original',
      field: 'original_price_sale',
      sortable: true,
      width: 110,
      cellRenderer: (props: any) => Preco_original(props),
    },
    {
      headerName: 'Poupança',
      cellRenderer: (props: any) => poupanca(props),
      sortable: true,
      width: 120,
    },

    {
      headerName: 'Opções',
      cellRenderer: () => butttonsCell(),

      width: 150,
    },
  ]

  return (
    <div
      className='ag-theme-material'
      style={{ height: '100%', width: '100%', color: 'white' }}
    >
      <AgGridReact
        rowData={listOfProducts}
        columnDefs={columnDefs}
        rowHeight={rowHeight}
        gridOptions={{ rowSelection: 'multiple' }}
        pagination
        animateRows
      />
    </div>
  )
}
